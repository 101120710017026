/* eslint-disable array-callback-return */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import Select from "react-select";
import { axiosInstance } from "../Config/AxiosInstance";

import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
// import PhoneInput from "react-phone-input-2";
// import "react-phone-input-2/lib/style.css";

import {
  Availability,
  // allowed,
  // amenities,
  GetData,
  GetDataone,
  Gettitle,
  Rent_Period,
  // types,
  //  amenities
} from "./Aminittes";
import SocialSidebar from "./SocialSidebar";
import axios from "axios";
import { useLocation, useNavigate } from "react-router-dom";
// import { GoogleMap, MarkerF } from "@react-google-maps/api";
const selectStyles = {
  menu: (provided) => ({
    ...provided,
    zIndex: 3,
  }),
  placeholder: (provided) => ({
    ...provided,
    color: "black",
  }),
};
function EditProperty() {
  const { state } = useLocation();
  const [selectedState, setSelectedState] = useState({
    value: state?.region,
    label: state?.region,
  });

  const [, setTableData] = useState([]);
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [, setDistricts] = useState([]);
  // const [selectedCountry, setSelectedCountry] = useState(null);
  const [selectedCity, setSelectedCity] = useState({
    value: state?.district,
    label: state?.district,
  });

  // console.log(selectedCountry?.value);
  const [, setLoading] = useState(false);
  const [, setError] = useState(null);

  const geonamesUsername = "sywyj";
  useEffect(() => {
    GetData().then((e) => {
      setfeature(e?.data?.data);
    });
    GetDataone().then((e) => {
      settype(e?.data?.data);
    });
    Gettitle().then((e) => {
      settitle(e?.data?.data);
    });
  }, []);
  // const countryOptions = [
  //   {
  //     value: 2300660,
  //     label: 'Ghana',
  //   },
  // ];

  useEffect(() => {
    const fetchStates = async () => {
      setLoading(true);
      try {
        const response = await axios.get(
          `https://secure.geonames.org/childrenJSON?geonameId=${2300660}&username=${geonamesUsername}`
        );
        const stateOptions = response.data.geonames.map((state) => ({
          value: state.geonameId,
          label: state.name,
        }));
        setStates(stateOptions);
      } catch (error) {
        setError("Error fetching states");
        console.error("Error fetching states:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchStates();
  }, []);

  useEffect(() => {
    if (selectedState) {
      const fetchCities = async () => {
        setLoading(true);
        try {
          const response = await axios.get(
            `https://secure.geonames.org/childrenJSON?geonameId=${selectedState.value}&username=${geonamesUsername}`
          );
          const cityOptions = response.data.geonames.map((city) => ({
            value: city.geonameId,
            label: city.name,
          }));
          setCities(cityOptions);
        } catch (error) {
          setError("Error fetching cities");
          console.error("Error fetching cities:", error);
        } finally {
          setLoading(false);
        }
      };

      fetchCities();
    } else {
      setCities([]);
      setDistricts([]);
    }
  }, [selectedState]);

  useEffect(() => {
    if (selectedCity) {
      const fetchDistricts = async () => {
        setLoading(true);
        try {
          const response = await axios.get(
            `https://secure.geonames.org/childrenJSON?geonameId=${selectedCity.value}&username=${geonamesUsername}`
          );
          const districtOptions = response.data.geonames.map((district) => ({
            value: district.geonameId,
            label: district.name,
          }));
          setDistricts(districtOptions);
        } catch (error) {
          setError("Error fetching districts");
          console.error("Error fetching districts:", error);
        } finally {
          setLoading(false);
        }
      };

      fetchDistricts();
    } else {
      setDistricts([]);
    }
  }, [selectedCity]);

  // const [selectedValues, setSelectedValues] = useState({});
  // console.log(datas, "datas");
  // const handleSelectChange = (e) => {
  //   setSelectedValues({
  //     ...selectedValues,
  //     [e.target.name]: e.target.value,
  //   });
  // };
  useEffect(() => {
    const fetchInitialData = async () => {
      try {
        const response = await axiosInstance.get("/country");
        setTableData(response?.data?.data || []);
      } catch (error) {
        console.error("Error fetching initial data", error);
      }
    };

    fetchInitialData();
  }, []);

  const [selectedAmenities, setSelectedAmenities] = useState(
    JSON.parse(state?.amenities) || []
  );
  console.log(state);

  const handleCheckboxChange = (event) => {
    const { id, checked } = event.target;

    setSelectedAmenities((prevSelected) => {
      if (checked) {
        return [...prevSelected, id];
      } else {
        return prevSelected.filter((item) => item !== id);
      }
    });
  };
  const [feature, setfeature] = useState([]);
  const [type, settype] = useState([]);
  const [title, settitle] = useState([]);

  const validationSchema = Yup.object().shape({
    title: Yup.string().required("Property Title is required"),
    price: Yup.string().required("Price is required"),
    description: Yup.string().required("Property Description is required"),
    type: Yup.string().required("Type is required"),
    rent_period: Yup.string().required("rent_period is required"),
    availability: Yup.string().required("availability is required"),
    no_of_bathroom: Yup.string(),
    garage: Yup.string(),
    // link: Yup.string(),
    sq_ft: Yup.string(),
    no_of_bedroom: Yup.string(),
    suburb: Yup.string(),
    address: Yup.string(),
    lease_term: Yup.string().required("Lease Term is required"),
  });
  const Navigate = useNavigate();
  const initialValues = {
    title: state?.title || "",
    description: state?.description || "",
    no_of_bathroom: state?.no_of_bathroom || "",
    garage: state?.garage || "",
    no_of_bedroom: state?.no_of_bedroom || "",
    sq_ft: state?.sq_ft || "",
    price: state?.price || "",
    rent_period: state?.rent_period || "",
    availability: state?.availability || "",
    lease_term: state?.lease_term || "",
    type: state?.type || "",
    country: state?.country || "",
    region: state?.region || "",
    address: state?.address || "",
    city: state?.city || "",
    suburb: state?.suburb || "",
    // link: state?.link || "",
    district: state?.district || "",
  };
  const formattedList =
    state?.listing_images?.map((item) => `${item.image}`) || [];

  const [images, setImages] = useState([]);
  const [imageURLs, setImageURLs] = useState(formattedList);

  useEffect(() => {
    const newImageUrls = images.map((image) => URL.createObjectURL(image));
    setImageURLs((prev) => [...prev, ...newImageUrls]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [images]);

  function onImageChange(e) {
    setImages([...e.target.files]);
  }

  function deleteImage(index, i) {
    // console.log(i);s
    const newImages = images.filter((_, i) => i !== index);
    const newImageURLs = imageURLs.filter((_, i) => i !== index);
    setImages(newImages);
    setImageURLs(newImageURLs);
  }

  function deleteAllImages() {
    setImages([]);
    setImageURLs(formattedList); // Reset to formattedList
  }
  const token = localStorage.getItem("Token");
  const handleSubmit = async (values, { setSubmitting, resetForm }) => {
    setSubmitting(true);
    const Data = new FormData();
    Data.append("title", values.title);
    Data.append("description", values.description);
    Data.append("price", values.price);
    Data.append("sq_ft", values.sq_ft);
    Data.append("no_of_bedroom", values.no_of_bedroom);
    Data.append("no_of_bathroom", values.no_of_bathroom);
    Data.append("garage", values.garage);
    Data.append("type", values.type);
    Data.append("rent_period", values.rent_period);
    Data.append("availability", values.availability);
    Data.append("country", "Ghana");
    Data.append("district", selectedCity.label);
    Data.append("region", selectedState.label);
    Data.append("city", values?.city);
    // Data.append("link", values?.link);
    // Data.append("region", selectedValues.region);
    Data.append("address", values.address);
    // Data.append("district", selectedValues.district);
    Data.append("amenities", JSON.stringify(selectedAmenities));
    Data.append("lease_term", values.lease_term);
    // Data.append("city", selectedValues.city);
    Data.append("suburb", values.suburb);

    images?.map((item, index) => {
      Data.append(`images[${index}][file]`, item);
    });

    try {
      await axios
        .post(`https://api.rentisready.com/api/listing/${state?.id}`, Data, {
          // .post(`https://lispiraapi.dev-sh.xyz/api/listing/${state?.id}`, Data, {
          headers: {
            Accept: "application/json",
            Authorization: `Bearer ${token}`,
            "X-Http-Method-Override": "PUT",
          },
        })
        .then((response) => {
          toast.success(response?.data?.message);
          if (response?.data?.status === true) {
            Navigate(-1);
          }
        });
    } catch (error) {
      toast.error(error?.message);
      console.error(error);
    }
  };

  return (
    <section
      id="add-property"
      className="add-property"
      style={{ marginTop: "0px" }}
    >
      <div className="container">
        <div className="row">
          <SocialSidebar />
          <div className="col-xs-12 col-sm-12 col-md-8">
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={handleSubmit}
              validateOnChange={true}
              validateOnBlur={true}
            >
              {({ isSubmitting, errors, touched }) => (
                <Form className="mb-0">
                  <div className="form-box">
                    <div className="row">
                      <div className="col-xs-12 col-sm-12 col-md-12">
                        <h4 className="form--title">Property Description</h4>
                      </div>
                      <div className="col-xs-12 col-sm-12 col-md-12">
                        <div className="form-group">
                          <label htmlFor="property-title">
                            Property Title*
                          </label>
                          <Field
                            as="select"
                            className={`form-control ${
                              errors.title && touched.title ? "is-invalid" : ""
                            }`}
                            id="select-Title"
                            name="title"
                          >
                            <option value="">Select Title</option>
                            {title.map((type) => (
                              <option key={type.name} value={type.name}>
                                {type.name}
                              </option>
                            ))}
                          </Field>

                          <ErrorMessage
                            name="title"
                            component="div"
                            className="invalid-feedback"
                          />
                        </div>
                      </div>
                      <div className="col-xs-12 col-sm-12 col-md-12">
                        <div className="form-group">
                          <label htmlFor="property-description">
                            Property Description*
                          </label>
                          <Field
                            as="textarea"
                            className={`form-control ${
                              errors.description && touched.description
                                ? "is-invalid"
                                : ""
                            }`}
                            name="description"
                            id="property-description"
                            rows={2}
                          />
                          <ErrorMessage
                            name="description"
                            component="div"
                            className="invalid-feedback"
                          />
                        </div>
                      </div>
                      <div className="col-xs-12 col-sm-4 col-md-4">
                        <div className="form-group">
                          <label htmlFor="select-type">Type*</label>
                          <Field
                            as="select"
                            className={`form-control ${
                              errors.type && touched.type ? "is-invalid" : ""
                            }`}
                            id="select-type"
                            name="type"
                          >
                            <option value="">Select Type</option>
                            {type.map((type) => (
                              <option key={type.name} value={type.name}>
                                {type.name}
                              </option>
                            ))}
                          </Field>
                          <ErrorMessage
                            name="type"
                            component="div"
                            className="invalid-feedback"
                          />
                        </div>
                      </div>
                      <div className="col-xs-12 col-sm-4 col-md-4">
                        <div className="form-group">
                          <label htmlFor="select-Rent Period">
                            Rent Period*
                          </label>
                          <Field
                            as="select"
                            className={`form-control ${
                              errors.rent_period && touched.rent_period
                                ? "is-invalid"
                                : ""
                            }`}
                            id="select-type"
                            name="rent_period"
                          >
                            <option value="">Select Rent Period</option>
                            {Rent_Period?.map((type) => (
                              <option key={type.name} value={type.name}>
                                {type.name}
                              </option>
                            ))}
                          </Field>
                          <ErrorMessage
                            name="rent_period"
                            component="div"
                            className="invalid-feedback"
                          />
                        </div>
                      </div>
                      <div className="col-xs-12 col-sm-4 col-md-4">
                        <div className="form-group">
                          <label htmlFor="select-Availability">
                            Availability*
                          </label>
                          <Field
                            as="select"
                            className={`form-control ${
                              errors.availability && touched.availability
                                ? "is-invalid"
                                : ""
                            }`}
                            id="select-type"
                            name="availability"
                          >
                            <option value="">Select availability</option>
                            {Availability?.map((type) => (
                              <option key={type.name} value={type.name}>
                                {type.name}
                              </option>
                            ))}
                          </Field>
                          <ErrorMessage
                            name="availability"
                            component="div"
                            className="invalid-feedback"
                          />
                        </div>
                      </div>
                      <div className="col-xs-12 col-sm-4 col-md-4">
                        <div className="form-group">
                          <label htmlFor="price">Price*</label>
                          <Field
                            type="text"
                            className={`form-control ${
                              errors.price && touched.price ? "is-invalid" : ""
                            }`}
                            name="price"
                            id="property-price"
                            required=""
                          />
                          <ErrorMessage
                            name="price"
                            component="div"
                            className="invalid-feedback"
                          />
                        </div>
                      </div>
                      <div className="col-xs-12 col-sm-4 col-md-4">
                        <div className="form-group">
                          <label htmlFor="price">Bathroom</label>
                          <Field
                            type="number"
                            className={`form-control ${
                              errors.no_of_bathroom && touched.no_of_bathroom
                                ? "is-invalid"
                                : ""
                            }`}
                            name="no_of_bathroom"
                            id="property-price"
                            required=""
                          />
                          <ErrorMessage
                            name="no_of_bathroom"
                            component="div"
                            className="invalid-feedback"
                          />
                        </div>
                      </div>
                      <div className="col-xs-12 col-sm-4 col-md-4">
                        <div className="form-group">
                          <label htmlFor="garage">Garage</label>
                          <Field
                            type="number"
                            className={`form-control ${
                              errors.garage && touched.garage
                                ? "is-invalid"
                                : ""
                            }`}
                            name="garage"
                            id="property-price"
                            required=""
                          />
                          <ErrorMessage
                            name="garage"
                            component="div"
                            className="invalid-feedback"
                          />
                        </div>
                      </div>
                      <div className="col-xs-12 col-sm-4 col-md-4">
                        <div className="form-group">
                          <label htmlFor="bed">Bed</label>
                          <Field
                            type="number"
                            className={`form-control ${
                              errors.no_of_bedroom && touched.no_of_bedroom
                                ? "is-invalid"
                                : ""
                            }`}
                            name="no_of_bedroom"
                            id="property-price"
                            required=""
                          />
                          <ErrorMessage
                            name="no_of_bedroom"
                            component="div"
                            className="invalid-feedback"
                          />
                        </div>
                      </div>
                      <div className="col-xs-12 col-sm-4 col-md-4">
                        <div className="form-group">
                          <label htmlFor="bed">Area sq_ft</label>
                          <Field
                            type="number"
                            className={`form-control ${
                              errors.sq_ft && touched.sq_ft ? "is-invalid" : ""
                            }`}
                            name="sq_ft"
                            id="property-price"
                            required=""
                          />
                          <ErrorMessage
                            name="sq_ft"
                            component="div"
                            className="invalid-feedback"
                          />
                        </div>
                      </div>

                      <div className="col-xs-12 col-sm-4 col-md-4">
                        <div className="form-group">
                          <label htmlFor="select-type">State*</label>
                          <div className="select--box">
                            <i className="fa fa-angle-down" />
                            <Select
                              placeholder="Select State/Region"
                              options={states}
                              styles={selectStyles}
                              defaultValue={selectedState}
                              onChange={setSelectedState}
                            />
                          </div>
                        </div>
                      </div>

                      <div className="col-xs-12 col-sm-4 col-md-4">
                        <div className="form-group">
                          <label htmlFor="District">District*</label>
                          <div className="select--box">
                            <i className="fa fa-angle-down" />
                            <Select
                              placeholder="Select District"
                              options={cities}
                              styles={selectStyles}
                              defaultValue={selectedCity}
                              onChange={setSelectedCity}
                            />
                          </div>
                        </div>
                      </div>

                      <div className="col-xs-12 col-sm-4 col-md-4">
                        <div className="form-group">
                          <label htmlFor="city">City/Town*</label>
                          <Field
                            type="text"
                            className={`form-control ${
                              errors.city && touched.city ? "is-invalid" : ""
                            }`}
                            name="city"
                            id="city"
                            disabled={selectedCity === null}
                          />
                          <ErrorMessage
                            name="city"
                            component="div"
                            className="invalid-feedback"
                          />
                        </div>
                      </div>
                      {/* <div className="col-xs-12 col-sm-4 col-md-4">
                        <div className="form-group">
                          <label htmlFor="link">Embeed Link</label>
                          <Field
                            type="text"
                            className={`form-control ${
                              errors.link && touched.link ? "is-invalid" : ""
                            }`}
                            name="link"
                            id="link"
                          />
                          <ErrorMessage
                            name="link"
                            component="div"
                            className="invalid-feedback"
                          />
                        </div>
                      </div> */}

                      <div className="col-xs-12 col-sm-4 col-md-4">
                        <div className="form-group">
                          <label htmlFor="suburb">Suburb</label>
                          <Field
                            type="text"
                            className={`form-control ${
                              errors.suburb && touched.suburb
                                ? "is-invalid"
                                : ""
                            }`}
                            name="suburb"
                            id="suburb"
                          />
                          <ErrorMessage
                            name="suburb"
                            component="div"
                            className="invalid-feedback"
                          />
                        </div>
                      </div>

                      <div className="col-xs-12 col-sm-4 col-md-8">
                        <div className="form-group">
                          <label htmlFor="address">Street And LandMark</label>
                          <Field
                            type="text"
                            className={`form-control ${
                              errors.address && touched.address
                                ? "is-invalid"
                                : ""
                            }`}
                            name="address"
                            placeholder="Street And LandMark (Opitional)"
                          />
                          <ErrorMessage
                            name="address"
                            component="div"
                            className="invalid-feedback"
                          />
                        </div>
                      </div>
                      <div className="col-xs-12 col-sm-4 col-md-12">
                        <div className="form-group">
                          <label htmlFor="Lease Term">Lease Term*</label>
                          <Field
                            as="textarea"
                            className={`form-control ${
                              errors.lease_term && touched.lease_term
                                ? "is-invalid"
                                : ""
                            }`}
                            rows={2}
                            name="lease_term"
                            id="leaseterm"
                          />
                          <ErrorMessage
                            name="lease_term"
                            component="div"
                            className="invalid-feedback"
                          />
                        </div>
                      </div>

                      <div className="form-box ">
                        <div className="row ">
                          <div className="col-xs-12 col-sm-12 col-md-12 mt-20">
                            <h4 className="form--title">Amenities</h4>
                          </div>
                          {feature?.map((amenity) => (
                            <div
                              key={amenity.name}
                              className="col-xs-12 col-sm-6 "
                            >
                              <div className="input-checkbox">
                                <label className="label-checkbox">
                                  <span>{amenity.name}</span>
                                  <input
                                    type="checkbox"
                                    id={amenity.name}
                                    onChange={handleCheckboxChange}
                                    checked={selectedAmenities.includes(
                                      amenity.name
                                    )}
                                  />
                                  <span className="check-indicator" />
                                </label>
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>

                      <div className="form-box">
                        <label htmlFor="image">Property Image</label>
                        <input
                          type="file"
                          multiple
                          accept="image/*"
                          className="form-control"
                          onChange={onImageChange}
                        />
                        <div className="image-preview">
                          {imageURLs.map((imageSrc, index) => (
                            <div
                              key={index}
                              style={{
                                position: "relative",
                                display: "inline-block",
                                margin: "10px",
                              }}
                            >
                              <img
                                src={imageSrc}
                                alt="not found"
                                width={"250px"}
                              />
                              <button
                                type="button"
                                onClick={() => deleteImage(index, imageSrc)}
                                style={{
                                  position: "absolute",
                                  top: 0,
                                  right: 0,
                                }}
                              >
                                <i
                                  class="fa fa-trash-o"
                                  style={{
                                    color: "red",
                                    fontSize: "20px",
                                  }}
                                ></i>
                              </button>
                            </div>
                          ))}
                        </div>
                        {imageURLs.length > 0 && (
                          <button
                            type="button"
                            className="btn btn--primary"
                            onClick={deleteAllImages}
                            style={{ marginTop: "10px" }}
                          >
                            <i
                              class="fa fa-trash-o"
                              style={{
                                color: "red",
                                fontSize: "20px",
                              }}
                            ></i>{" "}
                            Delete All
                          </button>
                        )}
                      </div>
                      {/* Other form fields... */}
                      <div className="col-xs-12 col-sm-12 col-md-12">
                        <div className="form-group">
                          <button type="submit" className="btn btn--primary">
                            {isSubmitting
                              ? "Adding Property..."
                              : "Add Property"}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </section>
  );
}

export default EditProperty;
